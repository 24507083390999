<template>
    <div>
        <div class="holder">
            <Header />
            <div class="prop-hero-section">

              <div class="hero-background">
                <div class="hero-content">
                  <h1>Do more with Shelta</h1>
                  <h6>Explore properties tailored just for you</h6>
                </div>

                <div class="container-fluid">
                  <div class="row" style="align-items: end;">
                    <div class="col-md-8">
                      <div class="search-box" v-if="filter === 'filter'">
                        <div class="prop-type-btn">
                          <button class="btn" :class="[propType == 3 ? 'active-type' : '']" @click="propType = 3; filterProperty();">Buy</button>
                          <button class="btn" :class="[propType == 1 ? 'active-type' : '']" @click="propType = 1; filterProperty()">Rent</button>
                        </div>

                        <div class="search-bar">
                          <img class="search-icon" src="../assets/icons/search-normal.svg" alt="search">
                          <input class="form-control search-input" v-model="search" type="text" name="search" id="search" placeholder="Search by city or state">
                          <button class="btn filter" :disabled="search == '' || loader2" @click="filterProperty()"><img src="../assets/icons/filter.svg" alt="filter"> 
                            Filter
                            <i
                              class="fa fa-spinner fa-spin  ml-3 f-12  btn-loader"
                              v-if="loader"
                            ></i>
                          </button>
                        </div>
                      </div>
                      <div v-if="filter === 'advance'">
                        <!-- Old filter -->

                        <div class="container" style="margin-bottom: 30px;">
                          <div class="row">
                            <div class="col-md-3 col-sm-6">
                              <div>
                                <form>
                                  <div class="form-group">
                                    <select
                                      class="form-control propertytype__filter"
                                      v-model="cityid"
                                    >
                                      <option value="">Desired location?</option>
                                      <option
                                        v-for="(city, index) in cities"
                                        :key="index"
                                        v-bind:value="city.id"
                                        >{{ city.name }}</option
                                      >
                                    </select>
                                  </div>
                                </form>
                              </div>
                            </div>
                            <div class="col-md-3 col-sm-6 d-none d-sm-none d-md-block">
                              <div class="amount__div">
                                <input
                                  v-model="minprice"
                                  class="form-control search-input"
                                  type="number"
                                  placeholder="Minimum amount"
                                />
                              </div>
                            </div>
                            <div class="col-md-3 col-sm-6 d-none d-sm-none d-md-block">
                              <div class="amount__div">
                                <input
                                  v-model="maxprice"
                                  class="form-control"
                                  type="number"
                                  placeholder="Maximum amount"
                                />
                              </div>
                            </div>
                            <div class="col-md-3 col-sm-6">
                              <div class="">
                                <div class="form-group">
                                  <select
                                    class="bedrooms__filter form-control"
                                    id="exampleFormControlSelect1"
                                    v-model="bedrooms"
                                  >
                                    <option value="">Bedrooms</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option class="3">3</option>
                                    <option class="4">4</option>
                                    <option class="5+">5+</option>
                                  </select>
                                </div>
                                <!-- <p>Bedrooms</p> -->
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-3 col-sm-6 d-none d-sm-none d-md-block">
                              <div class="">
                                <div class="form-group">
                                  <select class="bedrooms__filter form-control" id="which">
                                    <!-- <option value="">Bedrooms</option> -->
                                    <option value="rent">Rent</option>
                                    <option value="buy">Buy</option>
                                    <option value="sale">Sale</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-3 col-sm-6 d-none d-sm-none d-md-block">
                              <div class="">
                                <div class="form-group">
                                  <select
                                    class="propertytype__filter form-control"
                                    id="exampleFormControlSelect1"
                                    v-model="subtype"
                                  >
                                    <option class="" value="">Property Type</option>
                                    <option
                                      class="Residential"
                                      v-for="(sub, index) in subTypes"
                                      :key="index"
                                      v-bind:value="sub.id"
                                      >{{ sub.subtype }}</option
                                    >
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-3 col-sm-6 d-none d-sm-none d-md-block">
                              <div class="wfh__filter">
                                <div class="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="wfh"
                                    :value="wfh"
                                    v-model="wfh"
                                  />
                                  <label class="custom-control-label wfh-check" for="wfh"
                                    >WFH Ready</label
                                  >
                                </div>
                                <!-- <p>WHF Ready</p> -->
                              </div>
                            </div>
                            <div class="col-md-3 col-sm-6">
                              <div class="godiv">
                                <button
                                  @click="searchProperties()"
                                  class="btn btn-block gobtn"
                                >
                                  SEARCH
                                  <i
                                    class="fa fa-spinner fa-spin  ml-3 f-12  btn-loader"
                                    v-if="loader"
                                  ></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- Old filter -->
                      </div>
                    </div>
                    <div class="col-md-4" v-if="filter === 'filter'">
                      <div class="search-bar">
                        <button class="btn filter advance-search" @click="filter = 'advance'">Advance search</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>


            <div class="property-display">
                <div v-if="loader2" class="container-fluid">
                  <h5 class="header-propList">Best property offers around you</h5>
                  <Skeleton class="property-list" />
                </div>
                <div v-else class="container-fluid">
                  <h5 class="header-propList">Best property offers around you</h5>
                  

                  <PropertyCard :propertyList="properties" id="properties"></PropertyCard>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
import FooterMain from "../views/FooterMain";
// import Skeleton from "../components/SkeletonLoader.vue";
import Skeleton from "../components/SkeletonPropertyLoader.vue";
import PropertyCard from "./PropertyCard.vue";
import Header from "../views/Header";
import { Service } from "../store/service";
import $ from "jquery";
const Api = new Service();

export default {
  name: "PropertyListNew",
  components: {
    Header,
    FooterMain,
    PropertyCard,
    Skeleton
  },
  data() {
    return {
      loader2: false,
      properties: [],
      propType: 0,
      cities: "",
      subTypes: "",
      subtype: "",
      cityid: "",
      bedrooms: "",
      loader: false,
      filter: 'filter',
      search: '',
      minGap: 0,
      minRange: "0",
      maxRange: "100000000",
      maxprice: "",
      minprice: "",
      wfh: "",
    };
  },
  mounted() {
    this.fetchProperties();
   
    this.getCities();
    this.getAllSubTypes();
   
  },
  methods: {
    async filterProperty() {
      this.loader2 = true;
      try {
        await Api.postRequest(`new_property_search`, {
          search: this.search,
          business_type: this.propType,
        }).then((res) => {
          this.loader2 = false;
          // this.propType = 0;
          if (res.data.success) {
            this.properties = res.data.properties;
            this.search = "";
          }
          
        }).catch(err => {
          console.log(err);
          this.loader2 = false;
        });
      } finally {
        // console.log("closed")
      }
    },
    searchProperties: async function() {
      this.loader = true;
      if (this.wfh === true) {
        this.wfh = "wfh";
      } else {
        this.wfh = "";
      }
      try {
        await Api.postRequest(`searchproperties`, {
          subtype: this.subtype,
          cityid: this.cityid,
          bedrooms: this.bedrooms,
          maxprice: this.maxprice,
          minprice: this.minprice,
          wfh: this.wfh
        })
          .then(res => {
            // console.log("filter search", res);
            this.properties = res.data.properties;
            this.loader = false;
            document.getElementById("properties").scrollIntoView();
          })
          .catch(err => {
            console.log(err);
            this.loader = false;
          });
      } finally {
        // console.log("Closed connection");
      }
    },
   
    getCities: function() {
      Api.getRequest(`fetchcitiesinnigeria`)
        .then(res => {
          this.$store.commit("setApiWarning", "Loading states...");
          if (res.data.success) {
            this.cities = res.data.cities;
            this.$store.commit("setApiWarning", "");
          } else if (res.data.error) {
            return this.$store.commit("setApiFailed", res.data.error);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    getAllSubTypes: function() {
      Api.getRequest(`fetchpropertysubtypes`)
        .then(res => {
          this.$store.commit("setApiWarning", "Loading states...");
          if (res.data.success) {
            this.subTypes = res.data.subtypes;
            this.$store.commit("setApiWarning", "");
          } else if (res.data.error) {
            return this.$store.commit("setApiFailed", res.data.error);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
   
    moveTo: function(id) {
      this.$router.push({
        path: `/property/${id}`
      });
    },
    fetchProperties: async function() {
      this.loader2 = true;
      try {
        await Api.getRequest(`fetchpropertiesrandomly`).then(res => {
          console.log("property list", res);
          this.properties = res.data.properties;
          this.loader2 = false;
        });
      } finally {
        // console.log("Closed connection");
      }
    },
    
  }
};
</script>

<style lang="scss" scoped>

$primary: #0033ea;
$secondary: #000000;
$tertiary: #a2b0e3;
$white: #ffffff;
$ashGrey: #555C74;

.prop-hero-section {
    // position: relative;
    background-image: linear-gradient(77.07deg, rgba(0, 0, 0, 0.105) -1.08%, rgba(0, 0, 0, 0.28) 29.76%, rgba(102, 102, 102, 0.175) 99.74%),
      url('../assets/images/new-prop-list.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    // height: 100vh;
    width: 100%;
    // height: 100%;
}


// Old filter starts
.bedrooms__filter {
  height: 60px;
    border-radius: 10px;
    background: $white;
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: $ashGrey;
    outline: 0;
    box-shadow: none;
}

.amount__div {
  input {
    // // border: 0.5px solid #0033ea;
    // border-radius: 0;
    // box-shadow: none;
    // font-size: 14px;
    // min-height: 56.74px;
    // font-family: Lato;
    // font-style: italic;
    // font-weight: 300;
    // color: #000000;

    height: 60px;
    border-radius: 10px;
    background: $white;
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: $ashGrey;
    outline: 0;
    box-shadow: none;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

// propertytype__filter

.propertytype__filter {
  height: 60px;
    border-radius: 10px;
    background: $white;
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: $ashGrey;
    outline: 0;
    box-shadow: none;
  // p {
  //   margin-bottom: 0;
  //   font-family: Lato;
  //   font-style: italic;
  //   font-weight: 300;
  //   font-size: 14px;
  //   line-height: 50px;
  //   color: $secondary;
  // }
}

.wfh__filter {
  // background: $white;
  // border: 0.5px solid #0033ea;
  // min-height: 56.74px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: left;
  padding-left: 10px;

  height: 60px;
    border-radius: 10px;
    background: $white;
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: $ashGrey;
    outline: 0;
    box-shadow: none;
}

.wfh-check {
  font-family: Lato;
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  color: $ashGrey;
}
.gobtn {
  background: $primary;
  color: $white;
  font-family: Lato;
  font-weight: normal;
  font-style: normal;
  font-size: 18px;
  box-shadow: none;
  outline: 0;
  min-height: 56.74px;
  border-radius: 10px;
}
.godiv {
  // margin: auto;
  // padding-bottom: 10px;
}

.showDrop {
  display: block;
}
.hideDrop {
  display: none;
}
.closeDropdown {
  position: absolute;
  bottom: 2%;
  right: 2.5%;
  display: flex;
  button {
    color: $white;
    background: $primary;
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    box-shadow: none;
    outline: 0;
    margin-bottom: 0.5rem;
  }
}

// .wfh-check::before {
//     margin-top: 0.7rem;
// }

// Main filter new ends

.search-select {
  // border: 0.5px solid $primary;
  // border-radius: 0;
  // box-shadow: none;
  // font-size: 14px;
  // min-height: 56.74px;
  // font-family: Lato2;
  // font-style: italic;
  // font-weight: 300;
  // color: $secondary;
  height: 60px;
    border-radius: 10px;
    background: $white;
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: $ashGrey;
    outline: 0;
    box-shadow: none;
}

.search-btn {
  font-family: Lato;
  background-color: $primary;
  color: $white;
  height: 2.9rem;
  border-radius: 0;
}
.advance-search-holder {
  display: flex;
}
.advance-search-holder > p {
  padding-top: 1rem;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 15px;
  align-items: center;
  color: $secondary;
}
.down-icon {
  padding-left: 1rem;
}
.range__value {
  position: absolute;
  bottom: 11%;
  display: flex;
  justify-content: space-between;
  div {
    input {
      height: 45px;
      border: 1px solid $primary;
      box-sizing: border-box;
      // padding: 0 10px;
    }
    h6 {
      padding-top: 0.5rem;
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 15px;
      // align-items: center;
      color: $secondary;
    }
  }
  div:first-child {
    input {
      margin-right: 5px;
    }
  }
  div:nth-child(2) {
    input {
      margin-left: 5px;
    }
  }
}

.range__value2 {
  // position: absolute;
  // bottom: 5%;
  padding-top: 2rem;
  display: flex;
  justify-content: space-between;
  div {
    input {
      height: 45px;
      border: 1px solid $primary;
      box-sizing: border-box;
      // padding: 0 10px;
    }
    h6 {
      padding-top: 0.5rem;
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 15px;
      // align-items: center;
      color: $secondary;
    }
  }
  div:first-child {
    input {
      margin-right: 5px;
    }
  }
  div:nth-child(2) {
    input {
      margin-left: 5px;
    }
  }
}

// .range-holder {
//   input {

//   }
// }

::-webkit-input-placeholder {
  /* Edge */
  font-family: Lato;
  font-weight: 300;
  font-size: 16px;
  line-height: 50px;
  color: #555C74;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: Lato;
  font-weight: 300;
  font-size: 16px;
  line-height: 50px;
  color: #555C74;
}

::placeholder {
  font-family: Lato;
  font-weight: 300;
  font-size: 16px;
  line-height: 50px;
  color: #555C74;
}

// Filter section ends
// Old filter ends



.hero-background {
  // position: absolute;
    //           top: 0;
    //           left: 0;
    //           width: 100%;
    //           height: 100%;
    //           display: flex;
    //           align-items: center;
    //           justify-content: center;
}

.hero-content {
    margin-top: 116px;
    padding: 0px 20px;
    h1 {
        padding-top: 60px;
        font-family: Lato;
        font-size: 56px;
        font-weight: 700;
        line-height: 70px;
        text-align: left;
        color: $white;
    }
    h6 {
      font-family: Lato;
      font-size: 18px;
      font-weight: 400;
      line-height: 22.5px;
      text-align: left;
      color: $white;
    }
}

.prop-type-btn {
  padding: 0px 20px;
  display: flex;
  button {
    font-family: Lato;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: $white;
    box-shadow: none;
    outline: 0;
  }
}

.active-type {
  border-bottom: 3px solid $white;
}

.search-bar {
  position: relative;
  padding: 0 20px;
  margin-top: 20px;
  margin-bottom: 50px;
  display: flex;
  .search-input, .filter {
    height: 60px;
    border-radius: 10px;
    background: $white;
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: $ashGrey;
    outline: 0;
    box-shadow: none;

  }
  .search-input {
    padding-left: 55px;
  }
  .filter {
    margin-left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin-right: 6px;
    }
  }
}
.search-icon {
  position: absolute;
  bottom: 18px;
  left: 38px;
}

.search-box {
  margin-top: 30px;
}

.header-propList {
  font-family: Lato;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  text-align: left;
  color: #2B3352;
  margin-top: 40px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 599px) {
  .hero-content {
    h1 {
      font-size: 36px;
    }
  }
  .header-propList {
    font-size: 25px;
  }
  .search-bar {
    margin-bottom: 0;
  }
  .advance-search {
    width: 100%;
    margin-left: 0 !important;
    margin-bottom: 20px;
  }
}

/* Mobile Styles */
@media (max-width: 768px) {
    .prop-hero-section {
        height: 100%; /* Adjust for mobile, 70% of the viewport height */
    }
}

/* For extra small devices */
@media (max-width: 480px) {
    .prop-hero-section {
        height: 100%; /* Adjust for smaller mobile devices */
    }
}

</style>