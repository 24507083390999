<template>
    <div>
        <div class="empty-holder">
            <img src="../assets/icons/empty-state.svg" alt="No recodes">
            <h5>{{ emptyData.header }}</h5>
            <h6>{{emptyData.option}}</h6>
        </div>
    </div>
</template>

<script>

export default {
    name: "EmptyState",
    props: {
        emptyData: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            loading: false,
            planType: 1,
            individualPlans: [],
            businessPlans: [],
        }
    },
    methods: {
    },
}
</script>

<style lang="scss" scoped>
$primary: #0033EA;
$white: #ffffff;
$asheGrey: #555C74;
$offBlack: #2B3352;

.empty-holder {
    padding: 40px 20px;
    text-align: center;
    h5 {
        font-family: Lato;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-align: center;
        color: $offBlack;
        margin-top: 30px;
    }
    h6 {
        font-family: Lato;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
        color: $asheGrey;
    }
}

</style>