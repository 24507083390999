<template>
    <div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-3 col-sm-12" style="margin-bottom: 30px;">
              <div class="property-preview-holder">
                <p class="prop-status"></p>
                <div>
                    <img class="d-block preview-img">
                </div>
                <div class="prop-preview-details">
                    <h5></h5>
                    <h6></h6>
                    <h4></h4>
                    <small></small>
                    <p></p>
                </div>
              </div>
          </div>
          <div class="col-md-3 col-sm-12" style="margin-bottom: 30px;">
              <div class="property-preview-holder">
                <p class="prop-status"></p>
                <div>
                    <img class="d-block preview-img">
                </div>
                <div class="prop-preview-details">
                    <h5></h5>
                    <h6></h6>
                    <h4></h4>
                    <small></small>
                    <p></p>
                </div>
              </div>
          </div>
          <div class="col-md-3 col-sm-12" style="margin-bottom: 30px;">
              <div class="property-preview-holder">
                <p class="prop-status"></p>
                <div>
                    <img class="d-block preview-img">
                </div>
                <div class="prop-preview-details">
                    <h5></h5>
                    <h6></h6>
                    <h4></h4>
                    <small></small>
                    <p></p>
                </div>
              </div>
          </div>
          <div class="col-md-3 col-sm-12" style="margin-bottom: 30px;">
              <div class="property-preview-holder">
                <p class="prop-status"></p>
                <div>
                    <img class="d-block preview-img" >
                </div>
                <div class="prop-preview-details">
                    <h5></h5>
                    <h6></h6>
                    <h4></h4>
                    <small></small>
                    <p></p>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
</template>


<script>


export default {
  name: "SkeletonPropertyLoader",
  components: {
  },
  data() {
    return {
    };
  },
  mounted() {
   
  },
  methods: {
    
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$white: #ffffff;
$yellow: #ffb100;
$asheGrey: #555C74;
$sheltaBlack: #2B3352;


    // PROPERTY CARD PREVIEW STARTS
.property-preview-holder {
  box-shadow: 0px 4px 15px 0px #00000026;
  border-radius: 10px;
  padding: 16px;
  position: relative;
  cursor: pointer;
  position: relative;
}

.prop-status {
  position: absolute;
  left: 20px;
  top: 20px;
  padding: 4px 12px;
  background: $white;
  color: $asheGrey;
  font-family: Lato;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  text-align: left;
  border-radius: 50px;
  border: 1px solid #b4bddd;
  animation: pulse-bg 1s infinite;
}

.status-text {
    background: $white;
    border-radius: 50px;
    position: absolute;
    left: 6%;
    top: 6%;
    padding: 4px 12px;
    span {
        font-family: Lato;
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
        text-align: left;
        color: $asheGrey;
    }
}
.image-container {
    max-width: 330px; /* Set the desired width */
    // height: auto;
    height: 193px;
    overflow: hidden
}
.preview-img {
    // border-radius: 10px;
    width: 100% !important;
    height: 150px !important;
    object-fit: cover;
    cursor: not-allowed;
    //   background: #eee;
    height: 13px;
    animation: pulse-bg 0.6s infinite;
}
.prop-preview-details {
  h5 {
    font-family: Lato;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    color: #555C74;
    margin-top: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    height: 12px;
    animation: pulse-bg 0.6s infinite;
  }
  h6 {
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #555C74;
    width: 100%;
    height: 12px;
    animation: pulse-bg 0.6s infinite;
  }
  h4 {
    font-family: Lato;
    font-size: 18px;
    font-weight: 600;
    line-height: 22.5px;
    text-align: left;
    color: #2B3352;
    width: 100%;
    height: 12px;
    animation: pulse-bg 0.6s infinite;
  }
  small {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: #555C74;
    margin-bottom: 10px;
    margin-top: 10px;
    display: flex;
    width: 100%;
    height: 12px;
    animation: pulse-bg 0.6s infinite;
  }
  p {
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: #3E4958;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    height: 12px;
    animation: pulse-bg 0.6s infinite;
    span {
      color: #EA0033;
    }
  }
}
// PROPERTY CARD PREVIEW ENDS

@keyframes pulse-bg {
  0% {
    background-color: #eee;
  }
  50% {
    background-color: #eaeaea;
  }
  100% {
    background-color: #eee;
  }
}
</style>